/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//外部商品相关
const goods = {
    name: 'goods',
    apis: {
        /**
         * 权益列表
         * @param params
         * @returns {*}
         */
        goodsList(params){
            return httpFetch('post', `/api/goods/goodsList`,params);
        },
		/**
		 * 权益列表
		 * @param params
		 * @returns {*}
		 */
		goodsShow(params){
			return httpFetch('post', `/api/goods/goodsShow`,params);
		},
		/**
		 * 权益列表
		 * @param params
		 * @returns {*}
		 */
		goodsEdit(params){
			return httpFetch('post', `/api/goods/goodsCreate`,params);
		},
        /**
         * 权益分享图坐标调整
         * @param params
         * @returns {*}
         */
        goodsAxisEdit(params){
            return httpFetch('post', `/api/goods/goodsAxisEdit`,params);
        },

    }
};
export default goods;
