/** @format */
/**
 * 路由配置对象化
 * @return {array}
 */
import Loadable from 'react-loadable';
import Loading from 'src/Components/Public/Loading/index';
const routes = [
    {
        path: '/login', //登录
        component: Loadable({ loader: () => import('src/Components/Login/index'), loading: Loading })
    },
    {
        path: '/', //主页
        component: Loadable({ loader: () => import('src/Components/Main/index'), loading: Loading }),
        routes: [
            {
                exact: true,
                path: '/', //欢迎页
                component: Loadable({ loader: () => import('src/Components/Welcome/index'), loading: Loading })
            },
            {
                exact: true,
                path: '/user', //用户组管理
                component: Loadable({ loader: () => import('src/Components/User/index'), loading: Loading })
            },
            {
                exact: true,
                path: '/subject', //科目一四的题目
                component: Loadable({ loader: () => import('src/Components/Subject'), loading: Loading })
            },
            {
                exact: true,
                path: '/pool', //科目一四题库
                component: Loadable({ loader: () => import('src/Components/Pool'), loading: Loading })
            },
            {
                exact: true,
                path: '/item', //权益购买
                component: Loadable({ loader: () => import('src/Components/Item/index'), loading: Loading })
            },
            {
                exact: true,
                path: '/goods', //权益购买
                component: Loadable({ loader: () => import('src/Components/Goods/index'), loading: Loading })
            },
            {
                exact: true,
                path: '/order', //用户支付订单
                component: Loadable({ loader: () => import('src/Components/Order'), loading: Loading })
            },
            {
                exact: true,
                path: '/payOrder', //商家支付管理
                component: Loadable({ loader: () => import('src/Components/PayOrder/index'), loading: Loading })
            },
            {
                exact: true,
                path: '/offer', //赠与奖励
                component: Loadable({ loader: () => import('src/Components/Offer/index'), loading: Loading })
            },
            {
                exact: true,
                path: '/comment', //评论及反馈维护
                component: Loadable({ loader: () => import('src/Components/Comment'), loading: Loading })
            },
            {
                exact: true,
                path: '/subjectComment', //科目评论
                component: Loadable({ loader: () => import('src/Components/SubjectComment'), loading: Loading })
            },
            {
                exact: true,
                path: '/frontResource', //版本号
                component: Loadable({ loader: () => import('src/Components/FrontResource'), loading: Loading })
            },
            {
                exact: true,
                path: '/resource', //资源维护
                component: Loadable({ loader: () => import('src/Components/Resource'), loading: Loading })
            },
            {
                exact: true,
                path: '/sourceSubject', //需要人工进行操作的源题
                component: Loadable({ loader: () => import('src/Components/SourceSubject'), loading: Loading })
            },
            {
                exact: true,
                path: '/coupon', //优惠券
                component: Loadable({ loader: () => import('src/Components/Coupon'), loading: Loading })
            },
            {
                exact: true,
                path: '/ruleIconType', //图标速记类型维护
                component: Loadable({ loader: () => import('src/Components/RuleIconType'), loading: Loading })
            },
            {
                exact: true,
                path: '/ruleIcon', //图标速记维护
                component: Loadable({ loader: () => import('src/Components/RuleIcon'), loading: Loading })
            },
            {
                exact: true,
                path: '/count', //数据统计
                component: Loadable({ loader: () => import('src/Components/Count'), loading: Loading })
            },

            {
              exact: true,
              path: '/version', //版本号
              component: Loadable({ loader: () => import('src/Components/Version'), loading: Loading })
            },
            {
                exact: true,
                path: '/chicken', //吃鸡
                component: Loadable({ loader: () => import('src/Components/Chicken'), loading: Loading })
            },
        ]
    }
];
export default routes;
